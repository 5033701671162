import React from 'react'
import { Box, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useSprings, animated, to } from 'react-spring'
import { useDrag } from '@use-gesture/react'

function getStyle({
  index,
  active,
  itemsCount = 1,
  rtl = false,
  theme = {},
  displayOnlyOne = false,
}) {
  const leftItemPosition = (active - 1 + itemsCount) % itemsCount
  const rightItemPosition = (active + 1) % itemsCount
  const isLeftItem = leftItemPosition === index
  const isRightItem = rightItemPosition === index
  const position = isLeftItem ? -1 : 1
  if (index === active)
    return {
      display: 'block',
      position: 'relative',
      x: 0,
      y: 0,
      z: 100,
      zIndex: 10,
      opacity: 1,
      backgroundColor: theme.palette.primary.dark,
      userSelect: 'none',
      cursor: 'grab',
    }
  if (isLeftItem || isRightItem)
    return {
      display: 'block',
      position: 'absolute',
      x: position * 250 * (rtl ? -1 : 1),
      y: displayOnlyOne ? 0 : 100,
      z: -10,
      zIndex: 5,
      opacity: displayOnlyOne ? 0 : 0.5,
      userSelect: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    }
  return {
    display: 'none',
    position: 'absolute',
    x: 0,
    y: 0,
    z: -10,
    zIndex: 0,
    opacity: 0.5,
    backgroundColor: 'transparent',
    userSelect: 'none',
    cursor: 'pointer',
  }
}

export default function Carousel({
  showCircleButtons = true,
  singleSlide = true,
  swipeable = true,
  itemWidth = 540,
  children,
}) {
  const [currIndex, setCurrentIndex] = React.useState(0)
  const theme = useTheme()
  const isRTL = theme.direction === 'rtl'
  const itemsCount = children.length
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const displayOnlyOne = useMediaQuery(theme.breakpoints.down('lg')) || singleSlide
  const [props, animationAPI] = useSprings(itemsCount, (index) =>
    getStyle({ index, active: 0, itemsCount, rtl: isRTL, theme, displayOnlyOne })
  )

  const animationStart = () => {
    animationAPI.start((index) =>
      getStyle({
        index,
        active: currIndex,
        itemsCount,
        rtl: isRTL,
        theme,
        displayOnlyOne,
      })
    )
  }
  React.useEffect(() => {
    animationStart()
  }, [currIndex])
  const next = () => setCurrentIndex((prevIndex) => (prevIndex + 1) % itemsCount)
  const prev = () => setCurrentIndex((prevIndex) => (prevIndex - 1 + itemsCount) % itemsCount)

  const bind = useDrag(({ down, movement: [mx], cancel, canceled }) => {
    if (swipeable) {
      if (Math.abs(mx) > (isSmallScreen ? 100 : 200) && !canceled) {
        cancel()
        if (mx > 0) next()
        else prev()
      } else {
        animationAPI.start((index) => {
          if (index === currIndex) {
            return {
              display: 'block',
              position: 'relative',
              x: down ? mx : 0,
              y: 0,
              z: 100,
              zIndex: 10,
              opacity: 1,
              backgroundColor: theme.palette.primary.dark,
              userSelect: 'auto',
              cursor: 'grab',
            }
          }
          return getStyle({
            index,
            active: currIndex,
            itemsCount,
            rtl: isRTL,
            theme,
            displayOnlyOne,
          })
        })
      }
    }
  })
  React.useEffect(() => {
    animationAPI.start((index) =>
      getStyle({
        index,
        active: currIndex,
        itemsCount,
        rtl: isRTL,
        theme,
        displayOnlyOne,
      })
    )
  }, [displayOnlyOne, isRTL])
  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
    >
      <Grid
        item
        xs
      >
        <Grid
          container
          sx={{ overflow: 'hidden' }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              style={{ position: 'relative', height: 'fit-content', transformStyle: 'preserve-3d' }}
            >
              {props.map(
                ({ x, y, z, opacity, display, position, pointerEvents, userSelect, cursor }, i) => (
                  <animated.div
                    {...bind()}
                    key={i}
                    style={{
                      touchAction: 'none',
                      display,
                      position,
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      margin: 'auto',
                      maxWidth: itemWidth,
                      userSelect,
                      pointerEvents,
                      zIndex: 10,
                      opacity: opacity.interpolate((op) => op),
                      cursor,
                      transform: to(
                        [x, y, z],
                        // eslint-disable-next-line no-shadow
                        (x, y, z) =>
                          `translate3d(${1.15 * x}px,${0.65 * y}px,${z}px) translateZ(${z}px)`
                      ),
                    }}
                  >
                    {React.cloneElement(children[i], {
                      ...children[i].props,
                      key: i,
                      onClick: () => {
                        // setCurrentIndex(i)
                      },
                    })}
                  </animated.div>
                )
              )}
            </Box>
          </Grid>
          {showCircleButtons && (
            <Grid
              item
              xs={12}
              mt={displayOnlyOne ? 2 : 10}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
              >
                {props.map(({ backgroundColor }, i) => (
                  <Grid
                    item
                    key={i}
                  >
                    <IconButton
                      size="small"
                      onClick={() => setCurrentIndex(i)}
                      aria-label="carousel button"
                    >
                      <animated.div
                        style={{
                          border: '2px solid',
                          borderColor: theme.palette.primary.dark,
                          backgroundColor,
                          borderRadius: '50%',
                          width: 20,
                          height: 20,
                        }}
                      />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
