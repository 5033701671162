import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import { useTranslation } from 'next-i18next'

const fixAnimateDirection = (animate) => {
  if (animate.includes('Left')) return animate.replace('Left', 'Right')
  if (animate.includes('Right')) return animate.replace('Right', 'Left')

  return animate
}

export default function ScrollAnimationRTL({ children, animateIn, ...props }) {
  const { i18n } = useTranslation()
  const direction = i18n.dir(i18n.language)
  return (
    <ScrollAnimation
      animateIn={direction === 'ltr' ? animateIn : fixAnimateDirection(animateIn)}
      {...props}
    >
      {children}
    </ScrollAnimation>
  )
}
