import * as React from 'react'

function BranchIcon(props) {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_389_10191)">
        <path
          d="M14.7656 24.0625H15.8594V25.1562H14.7656V24.0625Z"
          fill="#F3F5F7"
        />
        <path
          d="M19.1406 24.0625H20.2344V25.1562H19.1406V24.0625Z"
          fill="#F3F5F7"
        />
        <path
          d="M16.9531 24.0625H18.0469V25.1562H16.9531V24.0625Z"
          fill="#F3F5F7"
        />
        <path
          d="M14.7656 21.875H15.8594V22.9688H14.7656V21.875Z"
          fill="#F3F5F7"
        />
        <path
          d="M19.1406 21.875H20.2344V22.9688H19.1406V21.875Z"
          fill="#F3F5F7"
        />
        <path
          d="M16.9531 21.875H18.0469V22.9688H16.9531V21.875Z"
          fill="#F3F5F7"
        />
        <path
          d="M16.9531 26.25H18.0469V27.3438H16.9531V26.25Z"
          fill="#F3F5F7"
        />
        <path
          d="M16.9531 28.4375H18.0469V29.5312H16.9531V28.4375Z"
          fill="#F3F5F7"
        />
        <path
          d="M14.7656 26.25H15.8594V27.3438H14.7656V26.25Z"
          fill="#F3F5F7"
        />
        <path
          d="M19.1406 26.25H20.2344V27.3438H19.1406V26.25Z"
          fill="#F3F5F7"
        />
        <path
          d="M14.7656 28.4375H15.8594V29.5312H14.7656V28.4375Z"
          fill="#F3F5F7"
        />
        <path
          d="M19.1406 28.4375H20.2344V29.5312H19.1406V28.4375Z"
          fill="#F3F5F7"
        />
        <path
          d="M26.25 24.6094H22.4219V20.2344C22.4219 20.0893 22.3643 19.9502 22.2617 19.8477C22.1591 19.7451 22.02 19.6875 21.875 19.6875H13.125C12.98 19.6875 12.8409 19.7451 12.7383 19.8477C12.6357 19.9502 12.5781 20.0893 12.5781 20.2344V24.6094H8.75C8.60496 24.6094 8.46586 24.667 8.3633 24.7696C8.26074 24.8721 8.20312 25.0112 8.20312 25.1562V33.3594H7.10938V34.4531H27.8906V33.3594H26.7969V25.1562C26.7969 25.0112 26.7393 24.8721 26.6367 24.7696C26.5341 24.667 26.395 24.6094 26.25 24.6094ZM25.7031 25.7031V33.3594H22.4219V25.7031H25.7031ZM21.3281 20.7812V33.3594H19.6875V31.7188H20.2344V30.625H14.7656V31.7188H15.3125V33.3594H13.6719V20.7812H21.3281ZM18.5938 31.7188V33.3594H16.4062V31.7188H18.5938ZM9.29688 25.7031H12.5781V33.3594H9.29688V25.7031Z"
          fill="#F3F5F7"
        />
        <path
          d="M23.5156 26.7969H24.6094V27.8906H23.5156V26.7969Z"
          fill="#F3F5F7"
        />
        <path
          d="M23.5156 28.9844H24.6094V30.0781H23.5156V28.9844Z"
          fill="#F3F5F7"
        />
        <path
          d="M23.5156 31.1719H24.6094V32.2656H23.5156V31.1719Z"
          fill="#F3F5F7"
        />
        <path
          d="M10.3906 26.7969H11.4844V27.8906H10.3906V26.7969Z"
          fill="#F3F5F7"
        />
        <path
          d="M10.3906 28.9844H11.4844V30.0781H10.3906V28.9844Z"
          fill="#F3F5F7"
        />
        <path
          d="M10.3906 31.1719H11.4844V32.2656H10.3906V31.1719Z"
          fill="#F3F5F7"
        />
        <path
          d="M25.7031 2.1875H26.7969V3.28125H25.7031V2.1875Z"
          fill="#F3F5F7"
        />
        <path
          d="M27.3438 2.1875H28.4375V3.28125H27.3438V2.1875Z"
          fill="#F3F5F7"
        />
        <path
          d="M28.9844 2.1875H30.0781V3.28125H28.9844V2.1875Z"
          fill="#F3F5F7"
        />
        <path
          d="M25.7031 4.375H26.7969V5.46875H25.7031V4.375Z"
          fill="#F3F5F7"
        />
        <path
          d="M27.3438 4.375H28.4375V5.46875H27.3438V4.375Z"
          fill="#F3F5F7"
        />
        <path
          d="M28.9844 4.375H30.0781V5.46875H28.9844V4.375Z"
          fill="#F3F5F7"
        />
        <path
          d="M25.7031 6.5625H26.7969V7.65625H25.7031V6.5625Z"
          fill="#F3F5F7"
        />
        <path
          d="M27.3438 6.5625H28.4375V7.65625H27.3438V6.5625Z"
          fill="#F3F5F7"
        />
        <path
          d="M28.9844 6.5625H30.0781V7.65625H28.9844V6.5625Z"
          fill="#F3F5F7"
        />
        <path
          d="M31.7188 8.25593V1.64062C31.7185 1.35063 31.6031 1.0726 31.3981 0.867547C31.193 0.662492 30.915 0.547165 30.625 0.546875H25.1562C24.8663 0.547165 24.5882 0.662492 24.3832 0.867547C24.1781 1.0726 24.0628 1.35063 24.0625 1.64062V8.25593C22.3477 8.85445 21.3281 9.83651 21.3281 10.9375C21.3281 12.808 24.1493 14.2188 27.8906 14.2188C31.632 14.2188 34.4531 12.808 34.4531 10.9375C34.4531 9.83651 33.4336 8.85445 31.7188 8.25593ZM25.1562 1.64062H30.625V10.3906H29.5312V9.29688H30.0781V8.20312H25.7031V9.29688H26.25V10.3906H25.1562V1.64062ZM28.4375 9.29688V10.3906H27.3438V9.29688H28.4375ZM27.8906 13.125C24.7605 13.125 22.4219 11.9701 22.4219 10.9375C22.4219 10.4009 23.0503 9.83904 24.0625 9.42148V10.9375C24.0625 11.0825 24.1201 11.2216 24.2227 11.3242C24.3252 11.4268 24.4643 11.4844 24.6094 11.4844H31.1719C31.3169 11.4844 31.456 11.4268 31.5586 11.3242C31.6611 11.2216 31.7188 11.0825 31.7188 10.9375V9.42148C32.7309 9.83904 33.3594 10.4009 33.3594 10.9375C33.3594 11.9701 31.0207 13.125 27.8906 13.125Z"
          fill="#F3F5F7"
        />
        <path
          d="M4.92188 2.1875H6.01562V3.28125H4.92188V2.1875Z"
          fill="#F3F5F7"
        />
        <path
          d="M6.5625 2.1875H7.65625V3.28125H6.5625V2.1875Z"
          fill="#F3F5F7"
        />
        <path
          d="M8.20312 2.1875H9.29688V3.28125H8.20312V2.1875Z"
          fill="#F3F5F7"
        />
        <path
          d="M4.92188 4.375H6.01562V5.46875H4.92188V4.375Z"
          fill="#F3F5F7"
        />
        <path
          d="M6.5625 4.375H7.65625V5.46875H6.5625V4.375Z"
          fill="#F3F5F7"
        />
        <path
          d="M8.20312 4.375H9.29688V5.46875H8.20312V4.375Z"
          fill="#F3F5F7"
        />
        <path
          d="M4.92188 6.5625H6.01562V7.65625H4.92188V6.5625Z"
          fill="#F3F5F7"
        />
        <path
          d="M6.5625 6.5625H7.65625V7.65625H6.5625V6.5625Z"
          fill="#F3F5F7"
        />
        <path
          d="M8.20312 6.5625H9.29688V7.65625H8.20312V6.5625Z"
          fill="#F3F5F7"
        />
        <path
          d="M7.10938 14.2188C10.8507 14.2188 13.6719 12.808 13.6719 10.9375C13.6719 9.83651 12.6523 8.85445 10.9375 8.25593V1.64062C10.9372 1.35063 10.8219 1.0726 10.6168 0.867547C10.4118 0.662492 10.1337 0.547165 9.84375 0.546875H4.375C4.08501 0.547165 3.80698 0.662492 3.60192 0.867547C3.39687 1.0726 3.28154 1.35063 3.28125 1.64062V8.25593C1.56642 8.85445 0.546875 9.83651 0.546875 10.9375C0.546875 12.808 3.36803 14.2188 7.10938 14.2188ZM4.375 1.64062H9.84375V10.3906H8.75V9.29688H9.29688V8.20312H4.92188V9.29688H5.46875V10.3906H4.375V1.64062ZM7.65625 9.29688V10.3906H6.5625V9.29688H7.65625ZM3.28125 9.42148V10.9375C3.28125 11.0825 3.33887 11.2216 3.44143 11.3242C3.54399 11.4268 3.68308 11.4844 3.82812 11.4844H10.3906C10.5357 11.4844 10.6748 11.4268 10.7773 11.3242C10.8799 11.2216 10.9375 11.0825 10.9375 10.9375V9.42148C11.9497 9.83904 12.5781 10.4009 12.5781 10.9375C12.5781 11.9701 10.2395 13.125 7.10938 13.125C3.97926 13.125 1.64062 11.9701 1.64062 10.9375C1.64062 10.4009 2.26908 9.83904 3.28125 9.42148Z"
          fill="#F3F5F7"
        />
        <path
          d="M10.522 15.1154L11.3521 14.4033L14.6346 18.2303L13.8044 18.9424L10.522 15.1154Z"
          fill="#F3F5F7"
        />
        <path
          d="M20.3657 18.2451L23.6482 14.4181L24.4783 15.1301L21.1959 18.9571L20.3657 18.2451Z"
          fill="#F3F5F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_389_10191">
          <rect
            width={35}
            height={35}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default BranchIcon
