import axios from 'axios'
import authMiddleware from './middlewares/auth.middleware'
import responseMiddleware from './middlewares/response.middleware'

export const httpClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

authMiddleware(httpClient)
responseMiddleware(httpClient)

export default httpClient
