import React from 'react'
import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import NextLink from '@imports/NextLink'
// import AppStoreSvg from '@assets/icons/AppStoreSvg'
// import PlayStoreSvg from '@assets/icons/PlayStoreSvg'
// import ResponsiveButton from '@components/ResponsiveButton'
import getLocalizeImage from '@utils/getLocalizedImage'
import GetStartedButton from '@imports/GetStartedButton'
import { ArrowOutward } from '@mui/icons-material'

export function WhiteLabelSection() {
  const { t, i18n } = useTranslation(['common', 'white_label'])

  return (
    <Box
      py={15}
      sx={{
        backgroundImage: {
          xs: `url(${getLocalizeImage('white-label-mobile.webp')})`,
          md: `url(${getLocalizeImage('white-label.webp')})`,
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: { xs: 720, md: 800 },
      }}
    >
      <Container
        maxWidth="xl"
        dir="ltr"
        sx={{
          zIndex: 10,
          position: 'relative',
        }}
      >
        <Paper
          sx={{
            maxWidth: { xs: '90%', md: 570 },

            backgroundColor: { xs: 'transparent', md: 'primary.main' },
            boxShadow: { xs: 0, md: 1 },
            color: 'primary.contrastText',
            overflow: 'hidden',
            position: 'relative',
          }}
          dir={i18n.dir(i18n.language)}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: '#00000022',
              filter: 'drop-shadow(0px 0px 100px #00000022);',
              zIndex: -1,
            }}
          />

          <Box
            sx={{
              p: { xs: 3, md: 5 },
              py: { xs: 5, md: 5 },
            }}
          >
            <Grid
              container
              spacing={5}
            >
              <Grid
                item
                xs={12}
              >
                <Typography variant="h3">{t('white_label:make_it_your_own')}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  fontWeight={300}
                >
                  {t('white_label:No_two_schools_are_alike')}
                </Typography>{' '}
                <NextLink
                  href="/help-center"
                  style={{ textDecoration: 'underline' }}
                >
                  {t('common:Learn_more')}
                </NextLink>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <GetStartedButton
                  variant="contained"
                  color="secondary"
                  label={t('common:get_started_free')}
                  endIcon={<ArrowOutward />}
                />
              </Grid>
              {/* <Grid
              item
              xs={12}
            >
              <Typography
                variant="body2"
                sx={{ mb: 2 }}
                fontWeight={400}
                textTransform="capitalize"
                display="block"
              >
                {t('white_label:get_the_app_now')}
              </Typography>
              <Grid
                container
                direction="row"
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  sm="auto"
                >
                  <ResponsiveButton
                    variant="contained"
                    disableElevation
                    sx={{ textTransform: 'capitalize', fontWeight: 500 }}
                    size="large"
                    color="secondary"
                    endIcon={<PlayStoreSvg />}
                    component="a"
                    href="https://play.google.com/store/apps/details?id=com.blueassembly"
                    target="_blank"
                    aria-label="google play"
                    fullWidth
                  >
                    Google Play
                  </ResponsiveButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm="auto"
                >
                  <ResponsiveButton
                    variant="contained"
                    disableElevation
                    sx={{ textTransform: 'capitalize', fontWeight: 500, color: '#000' }}
                    size="large"
                    color="paper"
                    endIcon={<AppStoreSvg />}
                    component="a"
                    href="https://apps.apple.com/eg/app/blue-assembly/id1591786196"
                    target="_blank"
                    aria-label="app store"
                    fullWidth
                  >
                    App Store
                  </ResponsiveButton>
                </Grid>
              </Grid>
            </Grid> */}
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  )
}

export default WhiteLabelSection
