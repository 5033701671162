// import { toast } from 'react-toastify'

export default function notificationMiddleware(axiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        // if (window.location.pathname !== '/') window.location.replace('/')
      } else {
        // const message = error.response?.data?.message
        // if (message) toast.error(message)
      }
      return Promise.reject(error)
    }
  )
}
