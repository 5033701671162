import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { httpClient } from './httpClient'

export const KEYS = {
  COUNTRIES: 'countries',
  SCHOOL_INFO: 'school-info',
  GEOLOCATION: 'geolocation',
}

export const getCountries = async (tenant, token) => {
  const response = await httpClient.get('/countries', {
    headers: {
      subdomain: tenant,
      authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}
export const useCountries = (tenant, token) =>
  useQuery([KEYS.COUNTRIES, tenant], async () => getCountries(tenant, token), {
    enabled: Boolean(tenant && token),
  })

export const getSchoolInfo = async (tenant, token) => {
  const response = await httpClient.get('/school/info', {
    headers: {
      subdomain: tenant,
      authorization: `Bearer ${token}`,
    },
  })
  return response.data.data
}
export const useSchoolInfo = (tenant, token, locale = 'en') =>
  useQuery(
    [KEYS.SCHOOL_INFO, tenant, `locale=${locale}`],
    async () => getSchoolInfo(tenant, token),
    {
      enabled: Boolean(tenant && token),
    }
  )

export const useGetCountryCode = () =>
  useQuery(
    [KEYS.GEOLOCATION],
    async () => {
      const response = await axios.get('https://api.db-ip.com/v2/free/self')
      return response.data.countryCode
    },
    { staleTime: Infinity }
  )

export const useCreateSchoolInfo = (tenant, token) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) =>
      httpClient.post(`/school/info`, payload, {
        headers: {
          subdomain: tenant,
          authorization: `Bearer ${token}`,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.SCHOOL_INFO)
      },
    }
  )
}

export const useCreateContactUs = () =>
  useMutation((payload) => httpClient.post(`/contact-us`, payload))
