import React from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { useSprings, animated } from 'react-spring'
import Image from 'next/image'
// import { ArrowOutward } from '@mui/icons-material'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { useTranslation } from 'next-i18next'

// import GetStartedButton from '@imports/GetStartedButton'
import getLocalizeImage from '@utils/getLocalizedImage'
import MobileAppMascot from '@assets/mascots/MobileAppMascot'
import AppStoreSvg from '@assets/icons/AppStoreSvg'
import PlayStoreSvg from '@assets/icons/PlayStoreSvg'
import ResponsiveButton from '@components/ResponsiveButton'

const appFeatures = [
  {
    title: 'sharing_updates_and_announcements',
    description: 'Teachers_and_administrators_can_capture_snapshots',
    image: 'mobile2-posts.png',
  },
  {
    title: 'guardian_approvals_for_events',
    description: 'When_the_school_post_a_new_event_like_a_field_trip',
    image: 'mobile2-events.png',
  },
  {
    title: 'payments_for_parents',
    description: 'If_payment_is_required_for_a_field_trip',
    image: 'mobile2-payments.png',
  },
]

function getStyle({ index, active = 0 }) {
  const isActive = active === index
  return {
    opacity: 1 - Math.min(Math.abs(active - index) * 0.65, 0.9),
    paddingTop: isActive ? 3 : 0,
    paddingBottom: isActive ? 3 : 0,
    display: isActive ? 'flex' : 'none',
    position: isActive ? 'relative' : 'absolute',
    imageOpacity: isActive ? 1 : 0,
  }
}

export function MobileAppSection() {
  const { t, i18n } = useTranslation(['common', 'mobile_app'])
  const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1

  const [activeFeature, setActiveFeature] = React.useState(0)
  const itemsCount = appFeatures.length
  const [props, animationAPI] = useSprings(itemsCount, (index) => getStyle({ index, active: 0 }))
  const animationStart = () => {
    animationAPI.start((index) =>
      getStyle({
        index,
        active: activeFeature,
      })
    )
  }
  React.useEffect(() => {
    animationStart()
    const timer = setTimeout(() => {
      setActiveFeature((activeFeature + 1) % appFeatures.length)
    }, 5000)
    return () => clearInterval(timer)
  }, [activeFeature])
  return (
    <Box
      py={12}
      sx={{
        backgroundColor: { xs: 'primary.main', md: 'transparent' },
        color: 'white',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          // display: { xs: 'block', md: 'block' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          left: -50,
          top: -200,
          zIndex: 1,
        }}
      >
        <MobileAppMascot />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          top: 0,
          left: 0,
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
      >
        <ScrollAnimationRTL
          animateOnce
          animateIn="slideInLeft"
          style={{
            backgroundColor: '#F3F5F7',
            width: 350,
            height: '100%',
          }}
        />
      </Box>
      {/* to prevent mascot display over text */}
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          top: 0,
          right: 0,
          zIndex: 2,
          backgroundColor: 'primary.main',
          width: { xs: '100vw', md: 'calc(100vw - 350px)' },
        }}
      />
      <Container
        maxWidth={false}
        sx={{ position: 'relative', zIndex: 3 }}
      >
        <Grid
          container
          spacing={5}
          alignItems="stretch"
          justifyContent="center"
          position="relative"
          sx={{ flexWrap: { xs: 'wrap', md: 'noWrap' } }}
        >
          <Grid
            item
            xs={12}
            sx={{ pb: 8, display: { xs: 'block', md: 'none' } }}
          >
            <Typography
              fontWeight={600}
              variant="h5"
              textAlign="center"
              textTransform="capitalize"
            >
              {t('mobile_app:mobile_app_to_connect_parents')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md
            sx={{ position: 'relative', minHeight: { xs: 550, sm: 650 } }}
          >
            {props.map(({ imageOpacity }, index) => (
              <animated.div
                key={index}
                style={{
                  position: 'absolute',
                  opacity: imageOpacity.interpolate((op) => op),
                  alignItems: 'center',
                  maxWidth: 550,
                  margin: 'auto',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: 0,
                  right: 0,
                  zIndex: 3,
                }}
              >
                <ScrollAnimationRTL
                  animateOnce
                  delay={400}
                  duration={0.75}
                  animateIn="slideInLeft"
                >
                  <Image
                    layout="responsive"
                    src={getLocalizeImage(appFeatures[index].image)}
                    alt="system 01"
                    width={200}
                    height={200}
                  />
                </ScrollAnimationRTL>
              </animated.div>
            ))}
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <ScrollAnimationRTL
              animateOnce
              animateIn="fadeIn"
            >
              <Box>
                <Typography
                  fontWeight={600}
                  variant="h3"
                  sx={{ pb: 4, display: { xs: 'none', md: 'block' } }}
                >
                  {t('mobile_app:mobile_app_to_connect_parents')}
                </Typography>
                {props.map(({ opacity, paddingTop, paddingBottom }, index) => (
                  <animated.div
                    key={index}
                    style={{
                      paddingTop: paddingTop.interpolate((op) => op),
                      paddingBottom: paddingBottom.interpolate((op) => op),
                      opacity: opacity.interpolate((op) => op),
                    }}
                  >
                    <Grid
                      container
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'stretch',
                            my: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Divider
                              sx={{
                                transition: (theme) =>
                                  theme.transitions.create(['margin', 'height'], {
                                    easing: theme.transitions.easing.sharp,
                                    duration: theme.transitions.duration.leavingScreen,
                                  }),
                                mr: activeFeature === index ? 4 : 0,
                                borderWidth: 2,
                                height: activeFeature === index ? '90%' : 0,
                                borderRadius: 10,
                                display: { xs: 'none', md: 'block' },
                                opacity: activeFeature === index ? 1 : 0,
                                alignSelf: 'center',
                              }}
                              orientation="vertical"
                              flexItem
                              color="white"
                            />
                          </Box>
                          <Box py={1}>
                            <Typography
                              fontWeight={600}
                              variant="h5"
                              color="secondary.light"
                              sx={{ mb: 2, textAlign: { xs: 'center', md: 'unset' } }}
                              textTransform="capitalize"
                            >
                              {t(`mobile_app:features.${appFeatures[index].title}`)}
                            </Typography>
                            <Typography
                              fontWeight={500}
                              variant="h6"
                              sx={{ textAlign: { xs: 'center', md: 'unset' } }}
                            >
                              {t(`mobile_app:features.${appFeatures[index].description}`)}
                            </Typography>
                            <Box
                              sx={{ justifyContent: 'center', display: { xs: 'flex', md: 'none' } }}
                            >
                              <Divider
                                sx={{
                                  transition: (theme) =>
                                    theme.transitions.create(['width'], {
                                      easing: theme.transitions.easing.sharp,
                                      duration: theme.transitions.duration.leavingScreen,
                                    }),
                                  mt: 2,
                                  borderWidth: 2,
                                  borderRadius: 10,
                                  width: activeFeature === index ? '33%' : 0,
                                  minWidth: 0,
                                  opacity: activeFeature === index ? 1 : 0,
                                }}
                                flexItem
                                color="white"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </animated.div>
                ))}
                <Box sx={{ mt: 8, textAlign: { xs: 'center', md: 'left' } }}>
                  <Typography
                    variant="body2"
                    sx={{ mb: 3 }}
                    fontWeight={400}
                    textTransform="capitalize"
                    display="block"
                  >
                    {t('white_label:get_the_app_now')}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    sx={{ justifyContent: { xs: 'center', md: 'unset' } }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm="auto"
                    >
                      <ResponsiveButton
                        variant="contained"
                        disableElevation
                        sx={{ textTransform: 'capitalize', fontWeight: 500 }}
                        size="large"
                        color="secondary"
                        endIcon={<PlayStoreSvg />}
                        component="a"
                        href="https://play.google.com/store/apps/details?id=com.blueassembly"
                        target="_blank"
                        aria-label="google play"
                        fullWidth
                      >
                        Google Play
                      </ResponsiveButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm="auto"
                    >
                      <ResponsiveButton
                        variant="contained"
                        disableElevation
                        sx={{ textTransform: 'capitalize', fontWeight: 500, color: '#000' }}
                        size="large"
                        color="paper"
                        endIcon={<AppStoreSvg />}
                        component="a"
                        href="https://apps.apple.com/eg/app/blue-assembly/id1591786196"
                        target="_blank"
                        aria-label="app store"
                        fullWidth
                      >
                        App Store
                      </ResponsiveButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'unset' } }}>
                <GetStartedButton
                  color="paper"
                  endIcon={<ArrowOutward />}
                  sx={{ mt: 6 }}
                  label={t('common:get_started_free')}
                />
              </Box> */}
            </ScrollAnimationRTL>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default MobileAppSection
