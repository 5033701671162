import React from 'react'
import { CircularProgress } from '@mui/material'
import ResponsiveButton from '@components/ResponsiveButton'

function FlatButton({ isLoading, disabled, children, ...props }) {
  return (
    <ResponsiveButton
      disabled={disabled || isLoading}
      {...props}
      endIcon={isLoading ? <CircularProgress size={16} /> : null}
    >
      {children}
    </ResponsiveButton>
  )
}

export default FlatButton
