import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
// import Kidstent from '@assets/partners/kidstent.png'
// import Kidsclub from '@assets/partners/kidsclub.png'
// import Playgroup from '@assets/partners/playgroup.png'
// import LittleStars from '@assets/partners/little-stars.png'

// import Image from 'next/image'
import {
  ArrowOutward,
  // PlayCircleOutline,
} from '@mui/icons-material'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { useTranslation } from 'next-i18next'
import GetStartedButton from '@imports/GetStartedButton'
import Hero2Mascot from '@assets/mascots/Hero2Mascot'
import Hero2SmMascot from '@assets/mascots/Hero2SmMascot'
// import ResponsiveButton from '@components/ResponsiveButton'
import NextLink from '@imports/NextLink'

// const partnersLogos = [Kidstent, Kidsclub, Playgroup, LittleStars]

export function HeroSection() {
  const { t, i18n } = useTranslation(['common', 'hero'])
  const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1
  return (
    <Box
      py={10}
      sx={{ position: 'relative', zIndex: 1 }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          alignItems="center"
          spacing={8}
          sx={{ textAlign: { xs: 'center', md: 'inherit' } }}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <ScrollAnimationRTL
              animateOnce
              animateIn="fadeIn"
            >
              <Typography
                variant="h2"
                align="inherit"
                fontFamily={i18n.language === 'ar' ? 'inherit' : 'var(--lexend-deca-font)'}
                fontWeight={300}
                textTransform="capitalize"
              >
                <Typography
                  fontWeight={600}
                  fontFamily={i18n.language === 'ar' ? 'inherit' : 'var(--lexend-deca-font)'}
                  variant="h2"
                  component="span"
                >
                  {t('hero:all-in-one_solution')}
                </Typography>
                <br />
                {t('hero:for_school_communication')}
              </Typography>
            </ScrollAnimationRTL>
            <ScrollAnimationRTL
              animateOnce
              animateIn="fadeIn"
              delay={500}
            >
              <Box
                mt={8}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography
                  variant="subtitle2"
                  align="inherit"
                  color="neutral.dark"
                  fontWeight={600}
                >
                  {t('hero:Upgrade_you_school_by_getting_a_website')}
                </Typography>
              </Box>
            </ScrollAnimationRTL>
            <ScrollAnimationRTL
              animateOnce
              animateIn="fadeIn"
              delay={1000}
            >
              <Box pt={6}>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
                  alignItems="center"
                >
                  <Grid item>
                    <GetStartedButton
                      color="secondary"
                      endIcon={<ArrowOutward />}
                      label={t('common:get_started_free')}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      color="primary.dark"
                      component="h1"
                      // fontFamily="var(--cairo-font)"
                      variant="subtitle2"
                      fontWeight={600}
                      sx={{
                        my: 2,
                        ml: 2,
                        transition: (theme) =>
                          theme.transitions.create(['color'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                          }),
                        ':hover': {
                          color: 'secondary.main',
                        },
                      }}
                    >
                      <NextLink
                        href="https://meetings-eu1.hubspot.com/fatimah"
                        target="_blank"
                        scroll={false}
                        aria-label={t('common:Request_a_Demo')}
                        style={{ textDecoration: 'underline' }}
                      >
                        {t('common:Request_a_Demo')}
                      </NextLink>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </ScrollAnimationRTL>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              p={1}
              sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                display: 'flex',
                borderRadius: 2,
              }}
            >
              <video
                width="100%"
                muted
                autoPlay
                // controls
                poster="/images/video-poster.webp"
              >
                <source
                  // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  type="video/mp4"
                />
                <source
                  src="movie.ogg"
                  type="video/ogg"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={6}
          >
            <ScrollAnimationRTL
              animateOnce
              animateIn="fadeIn"
              delay={1000}
            >
              <Box pt={10}>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
                  alignItems="center"
                >
                  {partnersLogos.map((partnerLogo, index) => (
                    <Grid
                      item
                      key={index}
                    >
                      <Image
                        alt={t('hero:school_logo')}
                        src={partnerLogo}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </ScrollAnimationRTL>
          </Grid> */}
        </Grid>
      </Container>

      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          top: -88,
          zIndex: -1,
          opacity: 0.5,
        }}
      >
        <Hero2Mascot />
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          top: -88,
          width: '100%',
          zIndex: -1,
          opacity: 0.7,
        }}
      >
        <Hero2SmMascot
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  )
}

export default HeroSection
