import React from 'react'
import { Box, Container, Grid, Paper, Typography } from '@mui/material'
import LeftLine from '@assets/messages/left-line.svg'
import RightLine from '@assets/messages/right-line.svg'
import DotSvg from '@assets/messages/DotSvg'

import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import getLocalizeImage from '@utils/getLocalizedImage'

export function MessagingSection() {
  const { t, i18n } = useTranslation(['common', 'family_messaging'])
  const direction = i18n.dir?.(i18n.language) ?? 'ltr'
  return (
    <Box py={12}>
      <Container maxWidth="xl">
        <Typography
          variant="h3"
          fontWeight={700}
          sx={{ mb: { xs: 5, md: 3 } }}
          textAlign="center"
          textTransform="capitalize"
        >
          <Typography
            variant="h3"
            fontWeight={300}
            component="span"
            textAlign="center"
            textTransform="capitalize"
          >
            {i18n.language === 'en' && 'Streamlined '}
          </Typography>
          {t('family_messaging:family_messaging')}
        </Typography>
        <Box
          dir="ltr"
          sx={{
            direction: 'ltr',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              position: 'relative',
            }}
          >
            <Image
              style={{ width: '100%', maxWidth: 500, height: 'auto' }}
              src={getLocalizeImage('mobile-messages-sm.png')}
              alt="system 01"
              width={1000}
              height={1500}
            />
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              position: 'relative',
              maxWidth: { xs: '50%', md: 400 },
              transform: { xs: 'scale(1)', md: 'scale(0.84)', lg: 'scale(1)' },
            }}
          >
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                position: 'absolute',
                left: direction === 'ltr' ? -165 : 'unset',
                right: direction === 'rtl' ? -165 : 'unset',
                top: 72,
              }}
            >
              <LeftLine />
              <Box
                sx={{
                  position: 'absolute',
                  width: 350,
                  top: 122,
                  left: direction === 'ltr' ? -165 : 'unset',
                  right: direction === 'rtl' ? -165 : 'unset',
                  transform: 'rotateZ(-15deg)',
                }}
              >
                <Paper
                  sx={{
                    p: 1.5,
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                  >
                    {t('family_messaging:teacher_message')}
                  </Typography>
                </Paper>
                <Typography
                  variant="subtitle2"
                  color="primary.main"
                  fontWeight={600}
                  sx={{ ml: 1.5 }}
                  textTransform="capitalize"
                >
                  {t('family_messaging:teacher_reply')}
                </Typography>
              </Box>
            </Box>
            <Image
              // layout="responsive"
              style={{ width: 400, height: 'auto' }}
              src={getLocalizeImage('mobile-messages.png')}
              alt="system 01"
              width={1000}
              height={1500}
            />
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                position: 'absolute',
                right: direction === 'ltr' ? -132 : 'unset',
                left: direction === 'rtl' ? -132 : 'unset',
                top: 90,
              }}
            >
              <RightLine />
              <Box
                sx={{
                  position: 'absolute',
                  width: 375,
                  top: 108,
                  right: direction === 'ltr' ? -225 : 'unset',
                  left: direction === 'rtl' ? -225 : 'unset',
                  transform: 'rotateZ(-15deg)',
                }}
              >
                <Paper
                  sx={{
                    p: 1.5,
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                  >
                    {t('family_messaging:parent_message')}
                  </Typography>
                </Paper>
                <Typography
                  variant="subtitle2"
                  color="primary.main"
                  fontWeight={600}
                  sx={{ ml: 1.5 }}
                  textTransform="capitalize"
                >
                  {t('family_messaging:parent_messaging')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: { xs: 8, md: 2 } }}>
          <Box maxWidth={450}>
            <Grid
              container
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <DotSvg />
              </Grid>
              <Grid
                item
                xs
              >
                <Typography
                  variant="h5"
                  fontWeight={300}
                >
                  {t('family_messaging:Parents_and_teachers_are')}{' '}
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    component="span"
                  >
                    {t('family_messaging:exchanging_messages')}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box maxWidth={450}>
          <Grid
            container
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <DotSvg />
            </Grid>
            <Grid
              item
              xs
            >
              <Typography
                variant="h5"
                fontWeight={300}
              >
                <Typography
                  variant="h5"
                  fontWeight={600}
                  component="span"
                  textTransform="capitalize"
                >
                  {t('family_messaging:communication')}
                </Typography>{' '}
                {t('family_messaging:becomes_more_impactful_for_everyone_involved')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default MessagingSection
