import React from 'react'
import * as yup from 'yup'
import { Box, Container, Divider, Grid, Link, Paper, Typography } from '@mui/material'
import {
  Email,
  //  LocalPhone,
  //  LocationOn,
} from '@mui/icons-material'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import FlatTextField from '@components/FlatTextField'
import { useTranslation } from 'next-i18next'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useCreateContactUs } from '@api/common'
import FlatButton from '@components/FlatButton'

const contacts = [
  // {
  //   Icon: LocationOn,
  //   text: '6th floor, King Faisal Foundation, Riyadh, SA 12212',
  //   href: 'http://maps.google.com/',
  // },
  // {
  //   Icon: LocalPhone,
  //   text: '+966 54 636 3493',
  //   href: 'tel:+966546363493',
  // },
  {
    Icon: Email,
    text: 'info@blueassembly.com',
    href: 'mailto:info@blueassembly.com',
  },
]

const validationSchema = yup.object().shape({
  full_name: yup.string().required('Field is required'),
  email: yup.string().email().required('Field is required'),
  phone: yup.string().required('Field is required'),
  message: yup.string().required('Field is required'),
})

export function PreFooterSection() {
  const { t } = useTranslation(['common', 'pre_footer'])

  const [submitted, setSubmitted] = React.useState(false)
  const createContactMutation = useCreateContactUs()
  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      createContactMutation
        .mutateAsync(values)
        .then(() => setSubmitted(true))
        .catch(() => toast.error('Failed to submit'))
        .finally(() => actions.setSubmitting(false))
    },
  })

  return (
    <Box
      id="contact-us"
      sx={{
        background: 'linear-gradient(180deg, #3664A8 80%, #24426A 95%)',
        color: 'primary.contrastText',
        zIndex: 2,
        position: 'relative',
      }}
    >
      <Box py={15}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={7}
            justifyContent="center"
            position="relative"
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInLeft"
              >
                <Box maxWidth={500}>
                  <Typography
                    fontWeight={700}
                    variant="h3"
                    sx={{ pb: 6 }}
                    textTransform="capitalize"
                  >
                    {t('pre_footer:lets_get_in_touch')}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight={300}
                    sx={{ pb: 9 }}
                  >
                    {t('pre_footer:Still_unsure_if_Blue_Assembly_is_right_for_you')}
                  </Typography>
                  <Grid
                    container
                    spacing={5}
                  >
                    {contacts.map(({ Icon, text, href }, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                      >
                        <Link
                          target="_blank"
                          href={href}
                          sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
                          aria-label={text}
                        >
                          <Icon sx={{ mr: 2 }} />
                          {text}
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </ScrollAnimationRTL>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInRight"
              >
                <Paper sx={{ backgroundColor: 'neutral.main', p: { xs: 2, md: 4 } }}>
                  {submitted ? (
                    <Typography
                      textAlign="center"
                      variant="body1"
                      display="block"
                      color="success.main"
                    >
                      {t(
                        'pre_footer:Thanks_for_contacting_us_We_will_be_in_touch_with_you_shortly'
                      )}
                    </Typography>
                  ) : (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 4 }}
                      component="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <FlatTextField
                          required
                          fullWidth
                          label={t('pre_footer:form.Your_Name')}
                          placeholder={t('pre_footer:form.Your_Name')}
                          backgroundColor="white"
                          typographyProps={{ color: 'primary.main' }}
                          id="full_name"
                          name="full_name"
                          value={formik.values.full_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                          helperText={formik.touched.full_name && formik.errors.full_name}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={{ xs: 2, md: 4 }}
                      >
                        <Grid
                          item
                          xs={6}
                        >
                          <FlatTextField
                            required
                            fullWidth
                            label={t('pre_footer:form.Email')}
                            placeholder={t('pre_footer:form.Email_Address')}
                            backgroundColor="white"
                            typographyProps={{ color: 'primary.main' }}
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                        >
                          <FlatTextField
                            required
                            fullWidth
                            label={t('pre_footer:form.Phone')}
                            placeholder={t('pre_footer:form.Phone_Number')}
                            backgroundColor="white"
                            typographyProps={{ color: 'primary.main' }}
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <FlatTextField
                          required
                          fullWidth
                          label={t('pre_footer:form.Your_Message')}
                          placeholder={`${t('pre_footer:form.Enter_Your_Message_Here')}...`}
                          multiline
                          rows={3}
                          backgroundColor="white"
                          typographyProps={{ color: 'primary.main' }}
                          id="message"
                          name="message"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.message && Boolean(formik.errors.message)}
                          helperText={formik.touched.message && formik.errors.message}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="flex-end"
                      >
                        <Grid item>
                          <FlatButton
                            variant="contained"
                            color="secondary"
                            disableElevation
                            aria-label="submit contact form"
                            type="submit"
                            onClick={formik.handleSubmit}
                            isLoading={formik.isSubmitting}
                          >
                            {t('common:submit')}
                          </FlatButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </ScrollAnimationRTL>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <Divider color="white" />
      </Container>
    </Box>
  )
}

export default PreFooterSection
