import React from 'react'
import {
  TextField,
  Typography,
  outlinedInputClasses,
  InputAdornment,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material'

export const FlatTextField = React.forwardRef(
  (
    {
      label,
      StartIcon,
      EndIcon,
      size = 'medium',
      InputProps = {},
      inputProps = {},
      backgroundColor,
      typographyProps,
      sx = [],
      ...props
    },
    ref
  ) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const responsiveSize = isSmallScreen ? 'small' : size
    return (
      <>
        {!!label && (
          <Typography
            variant="body1"
            gutterBottom
            display="block"
            // fontSize={responsiveSize}
            sx={{ fontSize: responsiveSize }}
            // {...typographyProps}
          >
            {label}
          </Typography>
        )}
        <TextField
          inputRef={ref}
          sx={{
            [`& .${outlinedInputClasses.root}`]: {
              backgroundColor,
            },
            ...sx,
          }}
          size={responsiveSize}
          FormHelperTextProps={{ sx: { fontSize: responsiveSize } }}
          inputProps={{
            ...inputProps,
            style: { fontSize: responsiveSize, ...(inputProps.style ?? {}) },
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...InputProps,
            startAdornment: StartIcon ? (
              <InputAdornment position="start">
                <StartIcon
                  fontSize={responsiveSize}
                  sx={{ color: '#000' }}
                />
                <Divider
                  sx={{
                    height: { xs: 24, md: 36 },
                    mx: { xs: 1, md: 1.75 },
                    borderRightWidth: 1.5,
                    borderColor: 'primary.main',
                  }}
                  orientation="vertical"
                />
              </InputAdornment>
            ) : (
              InputProps?.startAdornment
            ),
            endAdornment: EndIcon ? (
              <InputAdornment position="end">
                <Divider
                  sx={{
                    height: 36,
                    mx: { xs: 1, md: 1.75 },
                    borderRightWidth: 1.5,
                    borderColor: 'primary.main',
                  }}
                  orientation="vertical"
                  color="primary.main"
                />
                <EndIcon
                  fontSize={responsiveSize}
                  sx={{ color: '#000' }}
                />
              </InputAdornment>
            ) : (
              InputProps?.endAdornment
            ),
          }}
          {...props}
        />
      </>
    )
  }
)

FlatTextField.displayName = 'FlatTextFieldRef'

export default FlatTextField
