import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'

import { useSprings, animated } from 'react-spring'
import Image from 'next/image'
import { Circle } from '@mui/icons-material'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { useTranslation } from 'next-i18next'
import getLocalizeImage from '@utils/getLocalizedImage'
import SystemMascot from '@assets/mascots/SystemMascot'
import SystemLeftSMMascot from '@assets/mascots/SystemLeftSMMascot'
import SystemRightSMMascot from '@assets/mascots/SystemRightSMMascot'

const systemFeatures = [
  {
    title: 'Create_school_levels',
    image: 'admin-levels.png',
  },
  {
    title: 'Create_classes',
    image: 'admin-classes.png',
  },
  {
    title: 'Add_students_to_classes',
    image: 'admin-students.png',
  },
  {
    title: 'Add_teachers_and_stuff',
    image: 'admin-staff.png',
  },
  {
    title: 'Add_guardians',
    image: 'admin-parents.png',
  },
]

function getStyle({ index, active = 0 }) {
  const isActive = active === index
  return {
    opacity: 1 - Math.min(Math.abs(active - index) * 0.65, 0.9),
    paddingTop: isActive ? 8 : 0,
    paddingBottom: isActive ? 8 : 0,
    display: isActive ? 'flex' : 'none',
    position: isActive ? 'relative' : 'absolute',
    imageOpacity: isActive ? 1 : 0,
  }
}

export function SystemSection() {
  const { t, i18n } = useTranslation(['common', 'system'])
  const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1
  const [activeFeature, setActiveFeature] = React.useState(0)
  const itemsCount = systemFeatures.length
  const [props, animationAPI] = useSprings(itemsCount, (index) => getStyle({ index, active: 0 }))
  const animationStart = () => {
    animationAPI.start((index) =>
      getStyle({
        index,
        active: activeFeature,
      })
    )
  }
  React.useEffect(() => {
    animationStart()
    const timer = setTimeout(() => {
      setActiveFeature((activeFeature + 1) % systemFeatures.length)
    }, 3000)
    return () => clearInterval(timer)
  }, [activeFeature])
  return (
    <Box sx={{ position: 'relative', zIndex: 1 }}>
      <Box
        py={15}
        sx={{
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            transform: `scaleX(${scaleX})`,
            position: 'absolute',
            left: 0,
            bottom: 0,
            opacity: 0.7,
          }}
        >
          <SystemMascot />
        </Box>
        <Container
          maxWidth="xl"
          sx={{ textAlign: { xs: 'center', md: 'inherit' }, zIndex: 1 }}
        >
          <Typography
            variant="h3"
            fontWeight={700}
            sx={{ mb: 10 }}
            textTransform="capitalize"
          >
            {t('system:school_administration_system')}
          </Typography>
          <Grid
            container
            spacing={6}
            flexDirection="row-reverse"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInRight"
              >
                <Box minWidth={350}>
                  <Typography
                    fontWeight={600}
                    variant="h4"
                    color="primary.main"
                    sx={{ pb: 2 }}
                  >
                    {t('system:Build_your_school_structure')}
                  </Typography>
                  {props.map(({ opacity, paddingTop, paddingBottom }, index) => (
                    <animated.div
                      key={index}
                      style={{
                        paddingTop: paddingTop.interpolate((op) => op),
                        paddingBottom: paddingBottom.interpolate((op) => op),
                        opacity: opacity.interpolate((op) => op),
                      }}
                    >
                      <Box py={1}>
                        <Grid
                          container
                          alignItems="center"
                          sx={{ justifyContent: { xs: 'center', md: 'inherit' } }}
                        >
                          {activeFeature === index && (
                            <Grid item>
                              <Circle
                                fontSize="inherit"
                                sx={{ fontSize: 12, color: 'primary.main', mr: 1 }}
                              />
                            </Grid>
                          )}
                          <Grid item>
                            <Typography
                              fontWeight={600}
                              variant="subtitle2"
                            >
                              {t(`system:features.${systemFeatures[index].title}`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </animated.div>
                  ))}
                </Box>
              </ScrollAnimationRTL>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInLeft"
              >
                {props.map(({ display, position, imageOpacity }, index) => (
                  <animated.div
                    key={index}
                    style={{
                      display,
                      position,
                      opacity: imageOpacity.interpolate((op) => op),
                      alignItems: 'center',
                      height: '100%',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    }}
                  >
                    <Image
                      layout="responsive"
                      src={getLocalizeImage(systemFeatures[index].image)}
                      alt={t(`system:features.${systemFeatures[index].title}`)}
                      width={200}
                      height={200}
                    />
                  </animated.div>
                ))}
              </ScrollAnimationRTL>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          left: 0,
          bottom: -75,
          opacity: 0.7,
        }}
      >
        <SystemLeftSMMascot />
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          bottom: -75,
          opacity: 0.7,
        }}
      >
        <SystemRightSMMascot />
      </Box>
    </Box>
  )
}

export default SystemSection
