import React from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { ArrowOutward, Pause } from '@mui/icons-material'

import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { useTranslation } from 'next-i18next'
import GetStartedButton from '@imports/GetStartedButton'
// import WebsiteMascot from '@assets/mascots/WebsiteMascot'
import getLocalizedVideo from '@utils/getLocalizedVideo'

import SchoolIcon from '@assets/icons/SchoolIcon'
import CalendarIcon from '@assets/icons/CalendarIcon'
import FinanceIcon from '@assets/icons/FinanceIcon'
import OutreachIcon from '@assets/icons/OutreachIcon'
import FormIcon from '@assets/icons/FormIcon'
import BranchIcon from '@assets/icons/BranchIcon'

const modules = [
  {
    Icon: BranchIcon,
    title: 'branches_management',
    description: 'Have_more_than_one_branch_for_your_school',
  },
  {
    Icon: SchoolIcon,
    title: 'school_visits',
    description: 'Through_your_website_parents_can_schedule_a_visit_for_your_school',
  },
  {
    Icon: CalendarIcon,
    title: 'attendance',
    description:
      'Through_our_mobile_app_your_teachers_can_easily_take_attendance_for_the_different_classes_throughout_the_day',
  },
  {
    Icon: FinanceIcon,
    title: 'finance',
    description:
      'Track_your_income_and_expense_mange_transactions_and_track_late_payments_by_parents',
  },
  {
    Icon: OutreachIcon,
    title: 'marketing_and_outreach',
    description:
      'You_can_reach_your_teachers_and_target_parents_through_emails_sms_and_WhatsApp_messages',
  },
  {
    Icon: FormIcon,
    title: 'custom_forms',
    description:
      'Customize_registration_forms_as_you_like_those_that_will_be_posted_on_your_school_website_to_let_parents_register_for_their_kids',
  },
]
export function WebsiteSection() {
  // const { t, i18n } = useTranslation(['common', 'website'])
  const { t } = useTranslation(['common', 'website'])
  // const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1
  return (
    <Box sx={{ position: 'relative', zIndex: 1, backgroundColor: 'white' }}>
      <Box
        py={15}
        sx={{ position: 'relative', display: 'flex', alignItems: 'center', overflow: 'hidden' }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={6}
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <Grid
              item
              xs={12}
              md="auto"
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInLeft"
              >
                <Box maxWidth={600}>
                  <Typography
                    variant="h4"
                    color="primary.main"
                    sx={{ pb: 8 }}
                  >
                    {t('website:A_website_for_you_school')}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ pb: 6 }}
                    textTransform="capitalize"
                  >
                    {t('website:student_admissions_become_online')}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ pb: 8 }}
                    display="block"
                    color="neutral.dark"
                  >
                    {t('website:Effortlessly_build_your_own_custom_website')}
                    <br />
                    <br />
                    {t('website:Simplify_the_enrollment_process_with_our_online_registration')}
                  </Typography>
                  <GetStartedButton
                    color="secondary"
                    endIcon={<ArrowOutward />}
                    label={t('common:get_started_free')}
                  />
                </Box>
              </ScrollAnimationRTL>
            </Grid>
            <Grid
              item
              xs={12}
              md
            >
              <ScrollAnimationRTL
                animateOnce
                animateIn="fadeInRight"
              >
                <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', position: 'relative' }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 10,
                      bottom: 10,
                      padding: 1,
                      borderRadius: '50%',
                      backgroundColor: '#333',
                      display: 'flex',
                    }}
                  >
                    <Pause sx={{ color: 'white', fontSize: 16 }} />
                  </Box>
                  <video
                    width="100%"
                    // height="450"
                    autoPlay
                    loop
                    muted
                    controls={false}
                  >
                    <source
                      src={getLocalizedVideo('website-placeholder.mp4')}
                      type="video/mp4"
                    />
                    <source
                      src={getLocalizedVideo('website-placeholder.webm')}
                      type="video/webm"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
                {/* <Image
                  layout="responsive"
                  src={getLocalizeImage('website-placeholder.gif')}
                  alt={t('website:website_image')}
                  height={450}
                  width={200}
                  style={{
                    borderRadius: '8px',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  }}
                /> */}
              </ScrollAnimationRTL>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl">
        <Divider variant="fullWidth" />
      </Container>
      <Box py={15}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            fontWeight={700}
            sx={{ mb: 10 }}
            textTransform="capitalize"
          >
            {t('website:more_modules')}...
          </Typography>
          <Grid
            container
            spacing={8}
            rowSpacing={10}
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            {modules.map(({ title, description, Icon }, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
              >
                <ScrollAnimationRTL
                  animateOnce
                  animateIn="fadeInUp"
                >
                  <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                  >
                    <Grid item>
                      <Box
                        sx={{
                          backgroundColor: 'primary.main',
                          p: 1.5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '100%',
                        }}
                      >
                        <Icon />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        textAlign="center"
                        variant="h5"
                        color="primary.main"
                        textTransform="capitalize"
                      >
                        {t(`website:modules.${title}`)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        textAlign="center"
                        variant="subtitle2"
                      >
                        {t(`website:modules.${description}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ScrollAnimationRTL>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          top: -500,
          zIndex: -1,
          opacity: 0.7,
        }}
      >
        <WebsiteMascot />
      </Box> */}
    </Box>
  )
}

export default WebsiteSection
