import * as React from 'react'

function FormIcon(props) {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_389_10279)">
        <path
          d="M10.5 35H24.5C27.3995 35 29.75 32.6495 29.75 29.75C29.75 26.8505 27.3995 24.5 24.5 24.5H10.5C7.60054 24.5 5.25 26.8505 5.25 29.75C5.25 32.6495 7.60054 35 10.5 35ZM10.5 25.6667H24.5C26.7552 25.6667 28.5833 27.4949 28.5833 29.75C28.5833 32.0051 26.7551 33.8333 24.5 33.8333H10.5C8.24482 33.8333 6.41669 32.0052 6.41669 29.75C6.41669 27.4948 8.24482 25.6667 10.5 25.6667Z"
          fill="#F3F5F7"
        />
        <path
          d="M32.0833 12.25H2.91669C1.30662 12.2519 0.00191406 13.5566 0 15.1667V19.8334C0.00191406 21.4434 1.30662 22.7481 2.91669 22.7501H32.0834C33.6934 22.7482 34.9981 21.4434 35.0001 19.8334V15.1667C34.9981 13.5566 33.6934 12.2519 32.0833 12.25ZM33.8333 19.8333C33.8333 20.7998 33.0498 21.5833 32.0833 21.5833H2.91669C1.95022 21.5833 1.16669 20.7998 1.16669 19.8333V15.1666C1.16669 14.2001 1.95022 13.4166 2.91669 13.4166H32.0834C33.0498 13.4166 33.8334 14.2002 33.8334 15.1666V19.8333H33.8333Z"
          fill="#F3F5F7"
        />
        <path
          d="M32.0833 0H2.91669C1.30662 0.00191406 0.00191406 1.30662 0 2.91669V7.58338C0.00191406 9.19338 1.30662 10.4981 2.91669 10.5001H32.0834C33.6934 10.4982 34.9981 9.19345 35.0001 7.58338V2.91669C34.9981 1.30662 33.6934 0.00191406 32.0833 0ZM33.8333 7.58331C33.8333 8.54978 33.0498 9.33331 32.0833 9.33331H2.91669C1.95022 9.33331 1.16669 8.54978 1.16669 7.58331V2.91669C1.16669 1.95022 1.95022 1.16669 2.91669 1.16669H32.0834C33.0498 1.16669 33.8334 1.95022 33.8334 2.91669V7.58331H33.8333Z"
          fill="#F3F5F7"
        />
        <path
          d="M8.75019 14.5833H4.0835C3.11703 14.5833 2.3335 15.3668 2.3335 16.3333V18.6666C2.3335 19.6331 3.11703 20.4166 4.0835 20.4166H8.75019C9.71672 20.4166 10.5002 19.6331 10.5002 18.6666V16.3333C10.5002 15.3668 9.71672 14.5833 8.75019 14.5833ZM8.16687 15.75L6.77446 16.8C6.56255 16.9576 6.27277 16.959 6.05929 16.8035L4.66687 15.75H8.16687ZM8.75019 19.25H4.0835C3.76132 19.25 3.50019 18.9889 3.50019 18.6667V16.3334L5.36688 17.7334C5.99312 18.1982 6.85034 18.1969 7.47501 17.7299L9.3335 16.3334V18.6667C9.3335 18.9889 9.07236 19.25 8.75019 19.25Z"
          fill="#F3F5F7"
        />
        <path
          d="M7.80573 5.13625C8.03856 4.83451 8.16544 4.46448 8.16681 4.08331C8.16681 3.11685 7.38327 2.33331 6.41681 2.33331C5.45034 2.33331 4.66681 3.11685 4.66681 4.08331C4.66879 4.46346 4.79566 4.8324 5.02788 5.13331C4.43486 5.57054 4.08438 6.26316 4.0835 7C4.0835 7.64436 4.60583 8.16669 5.25019 8.16669H7.5835C8.22785 8.16669 8.75019 7.64436 8.75019 7C8.74827 6.26425 8.39793 5.57286 7.80573 5.13625ZM5.83343 4.08331C5.83343 3.76114 6.09463 3.5 6.41674 3.5C6.73892 3.5 7.00005 3.7612 7.00005 4.08331C7.00005 4.40542 6.73885 4.66662 6.41674 4.66662C6.09463 4.66669 5.83343 4.40549 5.83343 4.08331ZM5.25012 7C5.25012 6.35565 5.77245 5.83331 6.41681 5.83331C7.06116 5.83331 7.5835 6.35565 7.5835 7H5.25012Z"
          fill="#F3F5F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8333 16.9167H15.75C16.0722 16.9167 16.3333 16.6555 16.3333 16.3334C16.3333 16.0112 16.0721 15.7501 15.75 15.7501H12.8333C12.5111 15.7501 12.25 16.0113 12.25 16.3334C12.25 16.6555 12.5112 16.9167 12.8333 16.9167Z"
          fill="#F3F5F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.9167 18.0833H12.8333C12.5111 18.0833 12.25 18.3444 12.25 18.6666C12.25 18.9888 12.5112 19.2499 12.8333 19.2499H30.9166C31.2388 19.2499 31.4999 18.9888 31.4999 18.6666C31.4999 18.3444 31.2389 18.0833 30.9167 18.0833Z"
          fill="#F3F5F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8333 4.66669H15.75C16.0722 4.66669 16.3333 4.40549 16.3333 4.08338C16.3333 3.76127 16.0722 3.5 15.75 3.5H12.8333C12.5111 3.5 12.25 3.7612 12.25 4.08331C12.25 4.40542 12.5112 4.66669 12.8333 4.66669Z"
          fill="#F3F5F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.9167 5.83331H12.8333C12.5111 5.83331 12.25 6.09451 12.25 6.41662C12.25 6.7388 12.5112 6.99993 12.8333 6.99993H30.9166C31.2388 6.99993 31.4999 6.73873 31.4999 6.41662C31.5 6.09451 31.2389 5.83331 30.9167 5.83331Z"
          fill="#F3F5F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3378 31.9124C15.543 32.1177 15.868 32.1408 16.1002 31.9667L20.7669 28.4667C21.0246 28.2734 21.0768 27.9078 20.8836 27.65C20.6902 27.3923 20.3247 27.34 20.0669 27.5333L15.805 30.73L14.9959 29.9209C14.767 29.6998 14.4032 29.7029 14.1782 29.928C13.9532 30.153 13.95 30.5168 14.1711 30.7457L15.3378 31.9124Z"
          fill="#F3F5F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_389_10279">
          <rect
            width={35}
            height={35}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default FormIcon
