/* eslint-disable no-param-reassign */
import { getAuth } from '@contexts/AuthContext'
import { i18n } from 'next-i18next'

export default function authMiddleware(axiosInstance) {
  axiosInstance.interceptors.request.use(
    async (config) => {
      config.params = config.params ?? {}
      const token = getAuth()?.access_token
      if (token) config.headers.Authorization = `Bearer ${token}`
      config.headers['Accept-Language'] = i18n?.language
      return config
    },
    (err) => Promise.reject(err)
  )
}
