import React from 'react'
import { Avatar, Box, Container, Divider, Grid, Paper, Typography } from '@mui/material'
import { CircleOutlined } from '@mui/icons-material'
import Carousel from '@components/Carousel'
import ScrollAnimationRTL from '@components/ScrollAnimationRTL'
import { random, sample } from 'lodash'
import { useTranslation } from 'next-i18next'

const testimonials = Array(5).fill({
  avatar: 'https://flowbite-svelte.com/images/profile-picture-2.webp',
  name: 'Mohammed Othman',
  role: 'Manager At Kids-Tent',
  caption: 'Manage and operate your institution through the easy-to-use admin panel',
})

function Testimonial({ avatar, name, role, caption, containerProps = {} }) {
  return (
    <Box {...containerProps}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 3,
          position: 'relative',
          filter: 'drop-shadow(0 2px 1px rgb(0 0 0 / 20%))',
          '& :after': {
            content: "''",
            position: 'absolute',
            borderLeft: '21px solid transparent',
            borderRight: '21px solid transparent',
            borderBottom: '21px solid',
            bottom: -15,
            left: { xs: 'calc(50% - 21px)', md: 7, lg: 12 },
            borderBottomColor: 'white',
            transform: 'rotate(180deg)',
            // filter: 'drop-shadow(0 0 30px #333)',
            zIndex: 0,
          },
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight={500}
        >
          &ldquo;{caption}&rdquo;
        </Typography>
      </Paper>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Avatar
            src={avatar}
            alt={`${name} avatar`}
            sx={{
              width: { md: 55, lg: 67 },
              height: { md: 55, lg: 67 },
            }}
            imgProps={{
              sx: {
                borderWidth: 2,
                borderColor: 'primary.main',
                borderStyle: 'solid',
                borderRadius: '100%',
              },
            }}
          />
        </Grid>
        <Grid
          item
          md
          xs={12}
        >
          <Typography
            color="primary.main"
            variant="h6"
            fontWeight={600}
            sx={{ textAlign: { xs: 'center', md: 'inherit' } }}
          >
            {name}
          </Typography>
          <Typography
            variant="caption"
            color="neutral.dark"
            fontWeight={400}
            display="block"
            sx={{ textAlign: { xs: 'center', md: 'inherit' } }}
          >
            {role}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
export function TestimonialsSection() {
  const { t } = useTranslation(['common', 'testimonials'])
  return (
    <Box pb={15}>
      <Container maxWidth="xl">
        <Box
          pt={1}
          pb={2}
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
        >
          <Divider
            orientation="vertical"
            color="black"
            sx={{
              height: 88,
              ml: '0.5px',
              mb: -0.5,
              borderWidth: 0.5,
              borderColor: 'primary.main',
            }}
          />
          <CircleOutlined sx={{ color: 'primary.main' }} />
        </Box>
        <Typography
          variant="h5"
          fontWeight={500}
          sx={{ mb: 5 }}
          textAlign="center"
          color="secondary.dark"
          textTransform="capitalize"
        >
          {t('testimonials:our_testimonials')}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={700}
          sx={{ mb: 12 }}
          textAlign="center"
          textTransform="capitalize"
        >
          {t('testimonials:what_people_are_saying')}...
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Grid
            container
            rowSpacing={18}
            columnSpacing={9}
            justifyContent="center"
          >
            {testimonials.slice(0, 5).map(({ avatar, name, role, caption }, index) => (
              <Grid
                key={index}
                item
                xs={4}
              >
                <ScrollAnimationRTL
                  animateOnce
                  animateIn={sample(['fadeInUp', 'fadeInDown'])}
                >
                  <Testimonial
                    avatar={avatar}
                    name={name}
                    role={role}
                    caption={caption}
                    containerProps={{
                      sx: {
                        transform: `translateY(${(index + 1) % 2 === 0 ? 0 : random(40, 90)}px)`,
                      },
                    }}
                  />
                </ScrollAnimationRTL>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Carousel
            itemWidth="100%"
            singleSlide
            showArrowButtons={false}
          >
            {testimonials.slice(0, 5).map(({ avatar, name, role, caption }, index) => (
              <Testimonial
                key={index}
                avatar={avatar}
                name={name}
                role={role}
                caption={caption}
              />
            ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  )
}

export default TestimonialsSection
