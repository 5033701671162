// import React from 'react'
// import { useQueryClient } from '@tanstack/react-query'
import { getCookie, hasCookie } from 'cookies-next'

// import { useUser } from '@api/auth'

const storageKey = 'admin'
// export const AuthContext = React.createContext()
// export function AuthContextProvider({ children }) {
//   const initialUser = JSON.parse(localStorage.getItem(storageKey))
//   const userQuery = useUser(initialUser)
//   const queryClient = useQueryClient()

//   const login = (user) => {
//     localStorage.setItem(storageKey, JSON.stringify(user))
//     queryClient.setQueryData('user', user)
//   }

//   const logout = () => {
//     localStorage.removeItem(storageKey)
//     // queryClient.invalidateQueries('user')
//     // queryClient.removeQueries('user') // clear query data
//     queryClient.resetQueries()
//     queryClient.setQueryData('user', undefined)
//   }

//   React.useEffect(() => {
//     if (initialUser) {
//       userQuery.refetch()
//     }
//   }, [])

//   return (
//     <AuthContext.Provider
//       // eslint-disable-next-line react/jsx-no-constructed-context-values
//       value={{
//         user: userQuery.data,
//         isAuthed: Boolean(userQuery.data),
//         login,
//         logout,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   )
// }

// eslint-disable-next-line import/prefer-default-export
export const getAuth = () => (hasCookie(storageKey) ? getCookie(storageKey) : undefined)

// export default AuthContextProvider
