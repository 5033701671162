import React from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import CountUp from 'react-countup'
import { useTranslation } from 'next-i18next'

const counters = [
  { title: 'students', count: 1500 },
  { title: 'teachers', count: 90 },
  { title: 'schools', count: 23 },
  { title: 'institutions', count: 11 },
]
export function NumbersSection() {
  const { t } = useTranslation(['common', 'numbers_talk'])
  return (
    <Box
      py={15}
      sx={{
        backgroundColor: 'white',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={12}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Grid
              container
              spacing={5}
              alignItems="center"
            >
              <Grid
                item
                xs={0}
                md={5}
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                <Divider
                  variant="middle"
                  sx={{
                    borderWidth: 2,
                    borderRadius: 10,
                    backgroundColor: '#000',
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
              >
                <Grid
                  container
                  justifyContent={{ xs: 'center', md: 'left' }}
                  spacing={{ xs: 1, md: 0 }}
                  flexDirection={{ xs: 'row', md: 'column' }}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      fontWeight={600}
                      // sx={{ textAlign: { xs: 'center', md: 'unset' } }}
                      textTransform="capitalize"
                    >
                      {t('numbers_talk:numbers')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      fontWeight={300}
                      textTransform="capitalize"
                    >
                      {t('numbers_talk:talk')}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ justifyContent: 'center', display: { xs: 'flex', md: 'none' } }}>
                  <Divider
                    sx={{
                      mt: 2,
                      borderWidth: 2,
                      borderRadius: 10,
                      width: '33%',
                      minWidth: 160,
                      backgroundColor: '#000',
                      display: { xs: 'block', md: 'none' },
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={0}
                md={5}
                sx={{ display: { xs: 'none', md: 'block' } }}
              />
              {/* <Grid
                item
                xs={12}
                md={7}
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  sx={{ textAlign: { xs: 'center', md: 'unset' } }}
                >
                  {t('numbers_talk:Manage_and_operate_your_institution')}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Grid
              container
              rowSpacing={10}
              columnSpacing={4}
            >
              {counters.map(({ title, count }, index) => (
                <Grid
                  key={index}
                  item
                  xs={6}
                >
                  <Grid
                    container
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="h3"
                        fontWeight={700}
                        gutterBottom
                      >
                        <CountUp
                          start={0}
                          end={count}
                          prefix="+"
                          enableScrollSpy
                          scrollSpyOnce
                        />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        color="primary.main"
                        textTransform="capitalize"
                      >
                        {t(`numbers_talk:counters.${title}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default NumbersSection
